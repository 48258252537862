<template>
  <div class="Title">
    <div class="title">服务订单</div>
    <div class="operate">
<!--      <div class="date_check">
        <div class="table">7天默认确认验收</div>
      </div>-->
      <div class="date_check">
        <span class="name">订单状态：</span>
        <div class="dropdown">
          <el-select v-model="queryState" clearable placeholder="选择状态" @clear="queryState = ''">
            <el-option v-for="(item, index) in states" :key="index" :value="item.value" :label="item.label">{{ item.label }}</el-option>
          </el-select>
        </div>
      </div>
      <div class="date_check">
        <span class="name">订单时间：</span>
        <el-date-picker v-model="queryDate" size="mini" style="height:41px" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        <el-button type="danger" style="margin-left: 20px" size="mini" round plain @click="handleClick">查询</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ORDER_STATE, SERVICE_ORDER_STATE } from "@/constants/order";

export default {
  name: "OrderTitle",
  props: {
    serviceOrder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    states() {
      let tmp = this.serviceOrder ? SERVICE_ORDER_STATE : ORDER_STATE
      return Object.values(tmp).filter(item => item.show)
    }
  },
  data() {
    return {
      queryState: '',
      queryDate: '',
    }
  },
  methods: {
    handleClick(){
      this.$emit("queryOrder", {state: this.queryState, date: this.queryDate})
    }
  },
}
</script>
<style lang="less" scoped>
.Title {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 700;
    color: #000000;
    font-size: 28px;
  }
  .operate {
    display: flex;
    align-items: center;
    .date_check {
      display: flex;
      align-items: center;
      .name {
        font-weight: 400;
        color: #000000;
        line-height: 23px;
        font-size: 20px;
        font-weight: 700;
      }
    }
    .dropdown {
      margin-right: 16px;
      width: 136px;
      height: 42px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
  }
}
/deep/ .el-range-editor--mini .el-range-separator {
  line-height: 30px;
}
/deep/ .el-range-editor--mini .el-range__icon {
  font-size: 22px;
  margin-top: 4px;
}
.table {
  width: 179px;
  height: 34px;
  background: #fff0f0;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #fc3737;
  line-height: 19px;
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    background: #f56c6c;
    color: #ffffff;
  }
}
</style>
