<!--
   取消订单 弹框
 -->
<template>
  <div class="Evaluate" v-if="open">
    <el-dialog title="评价" :visible.sync="open" class="Open" :before-close="handleClose" width="667px">
      <div class="desc">客观真实的评价能帮助用户做决策</div>
      <div class="from">
        <div class="from-data">
          <div class="name">服务态度：</div>
          <div class="input">
            <input type="text" v-model="orderInfo.attitude" />
          </div>
        </div>
        <div class="from-data">
          <div class="name">专业能力：</div>
          <div class="input">
            <input type="text" v-model="orderInfo.speciality" />
          </div>
        </div>
        <div class="from-data">
          <div class="name">交付质量：</div>
          <div class="input">
            <input type="text" v-model="orderInfo.quality" />
          </div>
        </div>
      </div>
      <div class="order-info">
        <img :src="order.avter" alt style="width:57px;height:42px" />
        <div class="title">{{ order.service_title }}</div>
      </div>
      <div class="Evaluate-num">
        <div class="name">描述相符</div>
        <div class="rate">
          <el-rate void-icon-class="el-icon-star-on" allow-half v-model="orderInfo.score" show-text></el-rate>
        </div>
      </div>
      <div class="Evaluate-submit" @click="postEvaluate">评价</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      open:      false,
      order:     "", //订单信息
      orderInfo: {
        score: 5,
        attitude: "", //态度
        quality: "", //质量
        speciality: "", //能力
      },
    };
  },
  methods: {
    handleClose() {
      this.open = false;
    },
    postEvaluate() {
      // 验证
      if (!this.orderInfo.attitude)
        return this.$modal.msgWarning("请填写服务态度!");
      if (!this.orderInfo.speciality)
        return this.$modal.msgWarning("请填写专业能力!");
      if (!this.orderInfo.quality)
        return this.$modal.msgWarning("请填写支付态度!");
      let url = "/app-api/hiring/service-order-evaluate/create";
      let datas = {
        ...this.orderInfo,
        serviceOrderId: this.order.id,
        serviceId: this.order.serviceId,
      };
      this.$axios
        .post(url, datas)
        .then((res) => {
          if (res.data.code === 0) {
            this.$modal.notifySuccess("评价成功~");
            this.open = false;
            this.$emit("List");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      this.orderInfo.attitude = "";
      this.orderInfo.quality = "";
      this.orderInfo.speciality = "";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 30px 40px 50px !important;
}
/deep/ .el-dialog {
  border-radius: 16px;
}
/deep/ .el-checkbox {
  width: 50%;
  margin-right: 0;
  margin-top: 28px;
}

/deep/ .el-checkbox__label {
  font-size: 16px;
}
/deep/ .el-checkbox__inner {
  width: 17px;
  height: 17px;
}
/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fc3737;
}
/deep/ .el-checkbox__inner::after {
  top: 2px;
  left: 6px;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fc3737;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fc3737;
  border-color: #fc3737;
}
/deep/ .el-checkbox__inner:hover {
  border-color: #fc3737;
}
/deep/ .el-dialog__headerbtn {
  z-index: 66;
  top: 35px;
  right: 47px;
  font-size: 18px;
}
/deep/ .el-dialog__title {
  font-size: 18px;
  color: #000000;
  position: relative;
  top: 22px;
  left: 20px;
}
/deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fc3737;
}
/deep/ .el-dialog__headerbtn:focus .el-dialog__close {
  color: #fc3737;
}
//
.Evaluate .desc {
  font-weight: 400;
  color: #999999;
  line-height: 19px;
  font-size: 16px;
  text-align: center;
}
.from {
  margin-top: 9px;
}
.Evaluate .from-data {
  height: 50px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.Evaluate .from-data .name {
  font-weight: 400;
  color: #999999;
  line-height: 19px;
  font-size: 16px;
}
.Evaluate .from-data .input,
.Evaluate .from-data .input input {
  height: 100%;
  font-size: 16px;
  width: calc(100% - 100px);
}
.Evaluate-num {
  display: flex;
  align-items: center;
  margin-top: 23px;
  .name {
    margin-right: 29px;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    font-size: 18px;
  }
  /deep/ .el-rate__icon {
    font-size: 24px;
  }
}
.Evaluate .order-info {
  display: flex;
  margin-top: 40px;
  align-items: center;
}
.Evaluate .order-info .title {
  font-weight: 400;
  color: #000000;
  line-height: 19px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.Evaluate-submit {
  width: 114px;
  height: 46px;
  background: #fc3737;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #ffffff;
  line-height: 23px;
  font-size: 20px;
  margin: 64px auto 0;
  cursor: pointer;
}
</style>
